import { Cell } from 'react-vant'
import style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
// import wx from "weixin-webview-jssdk"

const PlotListItem = (props) => {
  const navigate = useNavigate()

  const toPlotDetail = () => {
    navigate(`/Plot/Detail/${props.data?.id}`)
    // wx.miniProgram.postMessage({ data: { foo: 'bar' } })
  }
  return <div className={style.plotItem} onClick={toPlotDetail}>
    <div className={style.plotItem__plotname}>{props.data?.name}</div>
    <Cell titleClass={style.plotItem__title} valueClass={style.plotItem__value} title='地块负责人' value={props.data?.username} />
    <Cell titleClass={style.plotItem__title} valueClass={style.plotItem__value} title='地块面积（亩）' value={props.data?.area} />
  </div>
}
export default PlotListItem