import { Image } from 'react-vant'
import style from './index.module.scss'
import { useRef } from 'react'
import { Map, APILoader, TileLayer, Polygon, TileLayerType } from '@uiw/react-amap'
import { useParams } from 'react-router-dom'
import { getPlotLoad } from '../service'
import { useEffect, useState } from 'react'

const PlotMapView = (props) => {
  const mapRef = useRef()  // 地图
  const polygonRef = useRef()  // 多边形


  // 地图加载
  const mapComplete = async () => {
    let res = await getPlotLoad(props.plotLoadId)
    const lnglsts = []
    res.lnglats.forEach((l) => {
      lnglsts.push(new window.AMap.LngLat(l[0], l[1]))
    })
    polygonRef.current?.polygon.setPath(lnglsts)
    mapRef.current?.map.setFitView()
    mapRef.current?.map.setZoom(mapRef.current?.map.getZoom() + 2)
  }


  return <div className={style.map}>
    <Map ref={mapRef} onComplete={mapComplete}>
      <TileLayer type={TileLayerType.SATELLITE} />
      <Polygon ref={polygonRef} fillColor='#fff' strokeColor='blue' />
    </Map>
  </div>
}


const PlotDetail = () => {
  const [plotLadInfo, setPlotLadInfo] = useState()
  const params = useParams()
  document.title = '地块详情'

  useEffect(() => {
    const getData = async () => {
      let res = await getPlotLoad(params.id)
      setPlotLadInfo(res)
    }

    getData()
  }, [params])

  return <>
    <div className={style.map}>
      <APILoader version='2.0' akey="3d91e5b9e72ca06c0211fe218f7f09d9">
        <PlotMapView plotLoadId={params.id} />
      </APILoader>
    </div>

    <div className={style.detail}>
      <div className={style.detail__plotname}>
        <Image width='20' height='20' src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/Texture%402x.png' />
        <span>{plotLadInfo?.name}</span>
      </div>
      <div className={style.detail__msgGroup}>
        <div className={style.detail__tvCell}>
          <div className={style.detail__title}>地块负责人：</div>
          <div className={style.detail__value}>{plotLadInfo?.username}</div>
        </div>
        <div className={style.detail__tvCell}>
          <div className={style.detail__title}>地块位置：</div>
          <div className={style.detail__value}>{plotLadInfo?.address}</div>
        </div>
      </div>
      <div className={style.detail__msgGroup}>
        <div className={style.detail__tvCell}>
          <div className={style.detail__title}>周长（m）：</div>
          <div className={style.detail__value}>{plotLadInfo?.perimeter}</div>
        </div>
        <div className={style.detail__tvCell}>
          <div className={style.detail__title}>面积（㎡）：</div>
          <div className={style.detail__value}>{plotLadInfo?.areasqm}</div>
        </div>
        <div className={style.detail__tvCell}>
          <div className={style.detail__title}>经纬位置：</div>
          <div className={style.detail__value}>{plotLadInfo?.lnglats[0][0]} , {plotLadInfo?.lnglats[0][1]}</div>
        </div>
        <div className={style.detail__tvCell}>
          <div className={style.detail__title}>地块简介：</div>
          <div className={style.detail__value}>{plotLadInfo?.description}</div>
        </div>
      </div>
    </div>

  </>
}
export default PlotDetail