import style from './index.module.scss'
import { Button, Dialog, Flex, FloatingBall, Image, hooks } from 'react-vant'
import WorkOrderItem from '@/pages/PlantWorkOrder/components/WorkOrderItem'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { donePalntTask, getPalntTask } from '../service'

const PlantTaskManage = () => {
  document.title = '工单管理'
  const [taskInfo, setTaskInfo] = useState()
  const params = useParams()
  const { height } = hooks.useWindowSize()
  const navigate = useNavigate()

  const getData = async () => {
    let res = await getPalntTask(params.id)
    setTaskInfo(res)
  }

  useEffect(() => {
    getData()
  }, [params])

  const toPlantTaskCreate = () => {
    navigate('/WorkOrder/Create?taskid=' + params.id)
  }

  const donePlanTask = async () => {
    Dialog.confirm({
      message: '确认完成任务吗？'
    }).then(async () => {
      let res = await donePalntTask(params.id)
      if (res) {
        await getData()
      }
    }).catch(() => {
    })
  }



  return <>
    <div className={style.msg}>
      <div className={style.msg_tvCell}>
        <div className={style.msg_title}>任务名称：</div>
        <div className={style.msg_value}>{taskInfo?.name}</div>
      </div>
      <div className={style.msg_tvCell}>
        <div className={style.msg_title}>建议时间周期：</div>
        <div className={style.msg_value}>{taskInfo?.timepoint}</div>
      </div>
      <Flex justify='center' align='center'>
        <Flex.Item span={6}>
          <div className={style.msg_tjCell}>
            <div className={style.msg_tjNum}>{taskInfo?.orders.length}</div>
            <div className={style.msg_tjUnit}>个</div>
          </div>
          <div className={style.msg_tjTitle}>工作总量</div>
        </Flex.Item>
        <Flex.Item span={6}>
          <div className={style.msg_tjCell}>
            <div className={style.msg_tjNum}>{taskInfo?.numDoing}</div>
            <div className={style.msg_tjUnit}>个</div>
          </div>
          <div className={style.msg_tjTitle}>进行中</div>
        </Flex.Item>
        <Flex.Item span={6}>
          <div className={style.msg_tjCell}>
            <div className={style.msg_tjNum}>{taskInfo?.numDone}</div>
            <div className={style.msg_tjUnit}>个</div>
          </div>
          <div className={style.msg_tjTitle}>已完成</div>
        </Flex.Item>
        <Flex.Item span={6}>
          <div className={style.msg_tjCell}>
            <div className={style.msg_tjNum}>{(taskInfo?.rate * 100).toFixed(2)}</div>
            <div className={style.msg_tjUnit}>%</div>
          </div>
          <div className={style.msg_tjTitle}>完成度</div>
        </Flex.Item>
      </Flex>
    </div>
    <div style={{ height: height - 185, overflowY: 'auto' }}>
      {
        taskInfo?.orders.map(i => (
          <WorkOrderItem key={i.id} data={i} baseid={taskInfo?.baseid} onRefresh={getData} />
        ))
      }
    </div>
    <FloatingBall offset={{ right: 0, bottom: '15vh' }}>
      <Image src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/plus000%402x.png' onClick={toPlantTaskCreate}></Image>
    </FloatingBall>
    <div style={{ margin: 12 }}>
      <div className='btn_sub_bottom'>
        <Button round type="primary" block disabled={taskInfo?.status === '2'} onClick={donePlanTask}>
          {taskInfo?.status === '2' ? '已完成' : '确认完成'}
        </Button>
      </div>
    </div >

  </>

}
export default PlantTaskManage