import { HashRouter, Route, Routes } from 'react-router-dom'

import Home from './pages/Home'
import PlantingPlan from './pages/PlantingPlan'
import PlantPlot from './pages/Plot/PlantPlot'
import PlotDetail from './pages/Plot/Detail'
import PlantingPlanManage from './pages/PlantingPlan/Manage'
import PlantTaskManage from './pages/PlantTask/Manage'
import PlantWorkOrderCreate from './pages/PlantWorkOrder/Create'
import PlantWorkOrderSendWork from './pages/PlantWorkOrder/SendWork'
import PlantWorkOrderDetail from './pages/PlantWorkOrder/Detail'
import PlantWorkOrderReport from './pages/PlantWorkOrder/Report'
import PlantWorkOrderEdit from './pages/PlantWorkOrder/Edit'
import PlotManage from './pages/Plot/Manage'
import PersonManage from './pages/Person/Manage'
import Traceability from './pages/Traceability'
import TraceabilityDetailed from './pages/Traceability/Detailed'

function App () {
  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/PlantingPlan/:baseid/:userid/:stageTypes?' element={<PlantingPlan />} />
          <Route path='/PlantingPlan/Manage/:id/:stageTypes?' element={<PlantingPlanManage />} />
          <Route path='/Plot/Manage' element={<PlotManage />} />
          <Route path='/PlantPlot' element={<PlantPlot />} />
          <Route path='/Plot/Detail/:id' element={<PlotDetail />} />
          <Route path='/Task/Manage/:id' element={<PlantTaskManage />} />
          <Route path='/WorkOrder/Create' element={<PlantWorkOrderCreate />} />
          <Route path='/WorkOrder/Edit/:id' element={<PlantWorkOrderEdit />} />
          <Route path='/WorkOrder/SendWork' element={<PlantWorkOrderSendWork />} />
          <Route path='/WorkOrder/Detail/:id' element={<PlantWorkOrderDetail />} />
          <Route path='/WorkOrder/Report' element={<PlantWorkOrderReport />} />
          <Route path='/Person/Manage' element={<PersonManage />} />
          <Route path='/Traceability' element={<Traceability />} />
          <Route path='/Traceability/Detailed/:code' element={<TraceabilityDetailed />} />
        </Routes>
      </div>
    </HashRouter >
  )
}

export default App



