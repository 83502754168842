// import style from './index.module.scss'


const Traceability = () => {


  return <>
    <div>123</div>
  </>
}
export default Traceability