import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'react-vant'
import './index.scss'
import App from './App'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

