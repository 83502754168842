import { Space } from 'react-vant'
import style from './index.module.scss'
import { hooks } from 'react-vant'
import StageItem from '@/pages/PlantStage/components/StageItem'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getPlantingPlan } from '../service'

const PlantingPlanManage = () => {
  const [planInfo, setPlanInfo] = useState()
  const params = useParams()
  const { height } = hooks.useWindowSize()
  document.title = '种植管理'

  useEffect(() => {
    const getData = async () => {
      let res = await getPlantingPlan(params.id, params.stageTypes ? params.stageTypes.split(',') : [])
      setPlanInfo(res)
    }
    getData()
  }, [params])


  return <>
    <div className={style.manage}>
      <Space align='center'>
        <div className={style.manage_flag}></div>
        <span className={style.manage_ppname}>{planInfo?.name}</span>
      </Space>
      <div>
        <div style={{ display: 'flex' }}>
          <div className={style.manage__tvCell}>
            <div className={style.manage__title}>种植作物：</div>
            <div className={style.manage__value} style={{ width: 100 }}>{planInfo?.breed}</div>
          </div>
          <div className={style.manage__tvCell}>
            <div className={style.manage__title}>开始时间：</div>
            <div className={style.manage__value}>{planInfo?.planstartTime}</div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={style.manage__tvCell}>
            <div className={style.manage__title}>种植面积：</div>
            <div className={style.manage__value} style={{ width: 100 }}>{planInfo?.area.toFixed(2)}亩</div>
          </div>
          <div className={style.manage__tvCell}>
            <div className={style.manage__title}>结束时间：</div>
            <div className={style.manage__value}>{planInfo?.planendTime}</div>
          </div>
        </div>
        <div className={style.manage__tvCell}>
          <div className={style.manage__title}>种植地址：</div>
          <div className={style.manage__value}>{planInfo?.location}</div>
        </div>
      </div>
    </div>

    <div style={{ height: height - 160, overflowY: 'auto' }}>
      {
        planInfo?.stages.map(i => (
          <StageItem key={i.id} data={i} />
        ))
      }
    </div>
  </>
}
export default PlantingPlanManage