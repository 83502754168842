import request from "@/utils/request"

// 获取种植计划
export const getPlantingPlans = async (userid, baseid, status, page) => {
  let res = await request.get('/palntingplan', { params: { admin: userid, baseid: baseid, status: status, current: page } })
  return res
}

// 删除种植计划
export const deletePalntingplan = async (id) => {
  let res = await request.delete(`/palntingplan/${id}`)
  return res
}

// 完成种植计划
export const donePalntingplan = async (id) => {
  let res = await request.patch(`/palntingplan/${id}/done`)
  return res
}

// 获取种植计划
export const getPlantingPlan = async (id, stageTypes) => {
  let res = await request.get(`/palntingplan/${id}`, { params: { type: stageTypes } })
  return res
}

// 获取种植计划-采收
export const getPlantingPlanHarvests = async (userid, status, page) => {
  let res = await request.get('/harvest', { params: { admin: userid, status: status, current: page } })
  return res
}