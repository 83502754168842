import { Checkbox } from 'react-vant'
import style from './index.module.scss'
import { timeFormat } from '@/utils/timeHelper'

const SendWorkItem = (props) => {

  const showCheck = () => {
    if (props?.isSel) {
      return <Checkbox shape='square' checkedColor='#027160' style={{ marginRight: 12 }} name={props.data} />
    }
  }

  const showCreateDate = () => {
    if (!props?.isSel) {
      return <span className={style.personItem_createDate}>{timeFormat(props.data?.assignTime, 'yyyy-mm-dd hh:MM')}</span>
    }
  }

  return <div className={style.personItem}>
    {
      showCheck(props?.isSel)
    }
    <div style={{ flex: 1 }}>
      <div className={style.personItem_row1}>
        <div>
          <span className={style.personItem_cellTitle}>姓名：</span>
          <span>{props.data?.name}</span>
        </div>
        {
          showCreateDate(props?.isSel)
        }
      </div>
      <div className={style.personItem_row2}>
        <div>
          <span className={style.personItem_cellTitle}>性别：</span>
          <span>{props.data?.sex === '1' ? '男' : '女'}</span>
        </div>
        <div>
          <span className={style.personItem_cellTitle}>手机号：</span>
          <span>{props.data?.phone}</span>
        </div>
      </div>
    </div>

  </div>
}
export default SendWorkItem