import { Image } from 'react-vant'
import style from './index.module.scss'
import WorkOrderLogItem from '@/pages/PlantWorkOrder/components/WorkOrderLogItem'

const WorkOrderGroup = (props) => {

  return <>
    {
      props.data?.map(o => <div key={`o_${o.id}`}>
        <div className={style.groupItem}>
          <div className={style.groupItemRow}>
            <Image width={15} height={15} src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/jdm%402x.png' />
            <span>{o.name}</span>
          </div>
          <div className={style.groupItemRow}>
            <Image width={15} height={15} src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/time%402x.png' />
            <span>{o.planstartTime} 至 {o.planendTime}</span>
          </div>
          <div className={style.groupItemRow}>
            <Image width={15} height={15} src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/gdxx%402x.png' />
            <div className={style.groupItemRow_dynamic}>
              {
                o.dynamic && o.dynamic.map(d => {
                  return d.value ? <span >{`${d.key}：${d.value}\n`}</span> : null
                })
              }
            </div>
          </div>
        </div>
        {
          o.log.map(l =>
            <WorkOrderLogItem key={`l_${l.id}`} data={l} woperatorId={0} />
          )
        }
      </div>)
    }
  </>
}
export default WorkOrderGroup