import WorkOrderGroup from '../WorkOrderGroup'
import style from './index.module.scss'

const TraceItemPanes = (props) => {


  return <>
    {
      props.data?.groups.map(g => <div key={`g_${g.name}`}>
        <div className={style.lv2row}>
          <div className={style.lv2row_flag} />
          <span className={style.lv2row_title}>{g.name}</span>
        </div>
        <WorkOrderGroup data={g.orders} />
      </div>
      )
    }
  </>
}
export default TraceItemPanes