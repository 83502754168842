import axios from 'axios'
import { Toast } from 'react-vant'

// var request = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL
// })
var request = axios.create()

var t1
// 添加请求拦截器
request.interceptors.request.use((config) => {
  // 设置baseURL
  if (config.url.startsWith("/base") || config.url.startsWith("/users")) {
    config.baseURL = process.env.REACT_APP_PUBLIC_URL
  } else {
    config.baseURL = process.env.REACT_APP_BASE_URL
  }

  t1 = Toast({ type: 'loading', message: '加载中...', forbidClick: true, duration: 0 })
  return config
}, function (error) {
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use((response) => {
  t1?.clear()
  // 对响应数据做点什么
  if (response.status === 200) {
    if (response.data.success) {
      return response.data.data
    } else {
      console.error(response.data)
      Toast.fail(`${response.data.errorMessage}`)
    }
  } else {
    console.error(response)
    Toast.fail('API异常，请联系管理员。')
  }
}, function (error) {
  console.error(error)
  Toast.fail('服务出现异常，请联系管理员。')
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default request
