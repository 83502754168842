import { Divider, Button, Flex, Image, Dialog } from 'react-vant'
import style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { timeFormat } from '@/utils/timeHelper'
import { deletePalntingplan, donePalntingplan } from '../../service'

// props:{data,stageTypes,onRefresh}
const PlantingPlanListItem = (props) => {
  const navigate = useNavigate()

  const deletePlan = (id) => {
    Dialog.confirm({
      message: '确认删除种植计划吗？'
    }).then(async () => {
      let res = await deletePalntingplan(id)
      if (res.status === 1) {
        props.onRefresh?.()
      }
    }).catch(() => {
    })
  }

  const donePlan = (id) => {
    Dialog.confirm({
      message: '确认完成种植计划吗？'
    }).then(async () => {
      let res = await donePalntingplan(id)
      if (res) {
        props.onRefresh?.()
      }
    }).catch(() => {
    })
  }


  const toPlotList = (ids) => {
    navigate('/PlantPlot?ids=' + ids)
  }

  const toPlantingPlanManage = (id) => {
    navigate(`/PlantingPlan/Manage/${id}/${props.stageTypes ?? ''}`)
  }

  return <div className={style.ppItem}>
    <div className={style.ppItem__topflex} onClick={() => { toPlantingPlanManage(props.data?.id) }}>
      <Image width='115' height='75' src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/plantimg.png' />
      <div className={style.ppItem__txtGroup}>
        <div className={style.ppItem__txtGroupTop}>
          <span className={style.ppItem__name}>{props.data?.name}</span>
          <span className={style.ppItem__create}>创建时间：{timeFormat(props.data?.createtime)}</span>
        </div>
        <div className={style.ppItem__txtGroupBottom}>
          <span>计划完成度：</span>
          <span className={style.ppItem__degree}>{(props.data?.rate * 100).toFixed(2)}%</span>
        </div>
      </div>
    </div>
    <Divider hairline={false} style={{ marginBottom: 10 }} />
    <Flex >
      <Flex.Item span={12}>
        <Button round size='small' type='primary' onClick={() => { toPlotList(props.data?.plotlandIds) }}>查看地块</Button>
      </Flex.Item>
      <Flex.Item span={12} style={{ textAlign: 'end' }}>
        <Button round size='small' type='primary' plain style={{ display: (props.data?.status === '2' || props.stageTypes) ? 'none' : '' }}
          onClick={() => { deletePlan(props.data?.id) }}>删除</Button>
        <Button round size='small' type='primary' plain style={{ marginLeft: 10, display: (props.data?.status === '2' || props.stageTypes) ? 'none' : '' }}
          onClick={() => { donePlan(props.data?.id) }}>确认完成</Button>
      </Flex.Item>
    </Flex>
  </div>
}
export default PlantingPlanListItem