import { Button, Dialog, Divider, Popover, Space, Tag } from 'react-vant'
import style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { DeleteO, Edit, Ellipsis } from '@react-vant/icons'
import { timeFormat } from '@/utils/timeHelper'
import { delPlantOrder, donePlantOrder } from '../../service'


const WorkOrderItem = (props) => {
  const navigate = useNavigate()

  const actions = [{ text: '编辑', icon: <Edit /> }, { text: '删除', icon: <DeleteO /> }]

  const toWorkOrderDetail = () => {
    navigate('/WorkOrder/Detail/' + props.data?.id)
  }

  const toSendWork = () => {
    navigate(`/WorkOrder/SendWork?baseid=${props.baseid}&workorderid=${props.data?.id}`)
  }

  const popAction = (action, index) => {
    if (index === 0) {
      navigate(`/WorkOrder/Edit/${props.data?.id}`)
    } else if (index === 1) {
      Dialog.confirm({
        message: '确认删除工单吗？'
      }).then(async () => {
        let res = await delPlantOrder(props.data?.id)
        if (res) {
          props.onRefresh?.()
        }
      }).catch(() => {
      })
    }
  }

  const doneWorkOrder = () => {
    Dialog.confirm({
      message: '确认完成工单吗？'
    }).then(async () => {
      let res = await donePlantOrder(props.data?.id)
      if (res) {
        props.onRefresh?.()
      }
    }).catch(() => {
    })
  }

  return <div className={style.woItem}>
    <div className={style.woItem_row1}>
      <div className={style.woItem_name} onClick={toWorkOrderDetail}>{props.data?.name}</div>
      <Tag color='#E6FAF7' textColor='#00CBB3' className={style.woItem_tag} style={{ display: props.data?.status === '1' ? '' : 'none' }}>进行中</Tag>
      <Tag color='#FFf2e6' textColor='#FF7F00' className={style.woItem_tag} style={{ display: props.data?.status === '2' ? '' : 'none' }}>已完成</Tag>
      <Popover
        placement="left-start"
        actions={actions}
        reference={<Ellipsis rotate={90} color='#00898F' />}
        onSelect={popAction}
      />
    </div>
    <div className={style.woItem_createdate} onClick={toWorkOrderDetail}>创建时间：{props.data?.createTime?.substr(0, 10)}</div>
    <Divider hairline={false} style={{ marginBottom: 6, marginTop: 8, display: props.data?.status === '2' ? 'none' : '' }} />
    <Space justify='end' style={{ width: '100%', display: props.data?.status === '2' ? 'none' : '' }}>
      <Button round size='small' type='primary' plain onClick={toSendWork}>派工</Button>
      <Button round size='small' type='primary' onClick={doneWorkOrder}>确认完成</Button>
    </Space>
  </div>

}
export default WorkOrderItem