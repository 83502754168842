import { Cell, Image, Skeleton } from "react-vant"
import pca from 'china-division/dist/pca-code.json'
import { cosGetList } from '@/utils/cosHelper'
import style from './index.module.scss'
import { useEffect, useState } from "react"
import { decode } from "js-base64"
const JiDiPanes = (props) => {
  const [photos, setPhotos] = useState([])
  useEffect(() => {
    const getEntPhotos = async (path) => {
      const res = await cosGetList(path.substr(path.indexOf('files')))
      setPhotos(res.map(p => path.substr(0, path.indexOf('files')) + p.Key))
    }
    if (props.data?.imagePath)
      getEntPhotos(props.data?.imagePath)
  }, [props])

  const getlocation = (province, city, county) => {
    const provinceInfo = pca.find(p => p.code === province)
    const cityInfo = provinceInfo.children.find(c => c.code === city)
    const countyInfo = cityInfo.children.find(c => c.code === county)
    return provinceInfo.name + cityInfo.name + countyInfo.name
  }

  return <div>
    <Skeleton title row={8} rowHeight={30} loading={!props.data}>
      <Cell border={false} title='追溯码' value={props.number} />
      <Cell border={false} title='基地名称' value={props.data?.data.baseName} />
      <Cell border={false} title='基地地址' value={props.data ? getlocation(props.data.data.province, props.data.data.city, props.data.data.county) + props.data.data.location : ''} />
      <Cell border={false} title='基地面积' value={props.data?.data.totalArea + '亩'} />
      <Cell border={false} title='种植药材' value={props.data?.data.breedName} />
      <Cell border={false} title='负责人姓名' value={props.data?.data.admin?.name} />
      <Cell border={false} title='基地建设方式' value={props.data?.data.constructionMode} />
      <Cell border={false} title='基地类型' value={props.data?.data.baseType} />

      <div className={style.fuwenben}>
        <span dangerouslySetInnerHTML={{ __html: props.data?.data.instruction ? decode(props.data?.data.instruction.base64) : '' }} />
      </div>

      <div style={{ margin: '0 16px' }}>
        {
          photos.map(p => <Image key={p} fit='cover' src={p} />)
        }
      </div>
    </Skeleton>
  </div>
}
export default JiDiPanes