import style from './index.module.scss'
import TaskItem from '@/pages/PlantTask/components/TaskItem'


const StageItem = (props) => {

  return <div className={style.stageItem}>
    <div className={style.stageItem_name}>{props.data?.name}</div>
    {
      props.data?.tasks.map(i => (
        <TaskItem key={i.id} data={i} />
      ))
    }
  </div>

}
export default StageItem