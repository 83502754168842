import request from "@/utils/request"

// 获取种植计划关联地块列表
export const getPlotByIds = async (ids) => {
  let res = await request.get('/plotland', { params: { plotlandIds: ids } })
  return res
}

// 获取地块详细
export const getPlotLoad = async (id) => {
  let res = await request.get(`/plotland/${id}`)
  return res
}

// 获取地块，分页
export const getPlot = async (baseid, page) => {
  let res = await request.get('/base/plotland', { params: { baseid: baseid, current: page } })
  return res
}


