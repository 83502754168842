import { Button, Card, Form, Input, Toast, Uploader } from 'react-vant'
import style from './index.module.scss'
import { cosDelete, cosUpload } from '@/utils/cosHelper'
import { useNavigate, useSearchParams, useNavigationType } from 'react-router-dom'
import { submitReport, getUserInfo } from '../service'
import { useEffect, useState } from 'react'
import { useStore } from '@/store/useStore'

const PlantWorkOrderReport = () => {
  const [userInfo, setUserInfo] = useState(null)
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const store = useStore()
  const navigationType = useNavigationType()
  document.title = '任务上报'

  useEffect(() => {
    if (searchParams.get('userid')) {
      const getData = async () => {
        let res = await getUserInfo(searchParams.get('userid'))
        setUserInfo(res)
      }
      getData()
    } else if (store.userStore.userInfo) {
      setUserInfo(store.userStore.userInfo)
    } else {

    }
  }, [searchParams, store])

  const uploaderDelete = async (item) => {
    await cosDelete(item.url.substr(item.url.indexOf('files')))
  }


  const submit = async () => {
    if (!searchParams.get('orderid')) {
      Toast.fail('无效工单')
      return
    }
    if (userInfo === null) {
      Toast.fail('无效用户')
      return
    }
    let res = await submitReport({
      orderid: searchParams.get('orderid'),
      operatorId: userInfo?.id,
      operator: userInfo?.name,
      description: form.getFieldValue('description'),
      photos: form.getFieldValue('uploader') ? form.getFieldValue('uploader').map(e => { return e.url }) : []
    })
    if (res) {
      Toast.success({
        message: '上报成功', onClose: () => {
          if (navigationType === 'PUSH') {
            navigate(-1)
          } else {
            navigate(`/WorkOrder/Detail/${searchParams.get('orderid')}`, { replace: true })
          }
        }
      })
    }
  }
  return <>
    <Form form={form} layout='vertical'>
      <Card round className={style.card}>
        <Card.Body style={{ padding: 0 }}>
          <Form.Item border={false} name='description' label='任务上报'>
            <Input.TextArea rows={3} autoSize maxLength={140} showWordLimit style={{ background: '#f7f8fa' }} />
          </Form.Item>
        </Card.Body>
      </Card>
      <Form.Item name='uploader'>
        <Uploader previewSize={100} maxCount={9} multiple={true} upload={cosUpload} onDelete={uploaderDelete} />
      </Form.Item>


    </Form>
    <div style={{ margin: 12 }}>
      <div className='btn_sub_bottom'>
        <Button round type="primary" block onClick={submit}>任务上报</Button>
      </div>
    </div >
  </>
}
export default PlantWorkOrderReport