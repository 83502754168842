import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { FloatingBall, Picker, Image } from 'react-vant'
import { getBaseByUser } from '../../service'

const SelectBaseByUser = (props) => {
  const [baseList, setBaseList] = useState([])
  const pickerRef = useRef()

  useEffect(() => {
    if (!props.baseid) {
      const getData = async () => {
        setBaseList(await getBaseByUser(props.userid))
        pickerRef.current?.open()
      }
      getData()
    }
  }, [])



  return <>
    <FloatingBall offset={{ right: 0, bottom: '15vh' }} style={{ display: props.userid ? '' : 'none' }}>
      <Image src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/plus000%402x.png'
        onClick={() => { pickerRef.current?.open() }}></Image>
    </FloatingBall>
    <Picker ref={pickerRef} popup title='切换基地' columnsFieldNames={{ text: 'baseName', value: 'id' }} columns={baseList}
      placeholder={false} onConfirm={props.onBaseChange}></Picker>
  </>
}
export default SelectBaseByUser