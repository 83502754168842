
import { Button, Checkbox, Tabs, Toast } from 'react-vant'
import SendWorkItem from '../components/SendWorkItem'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getSendWorkUser, getSendWorkUserAssign, submitSendWork } from '../service'

const PlantWorkOrderSendWork = () => {
  const [users, setUsers] = useState([])
  const [assignUsers, setAssignUsers] = useState([])
  const [selectUsers, setSelectUsers] = useState([])
  const [searchParams] = useSearchParams()
  document.title = '派工管理'


  const getUsers = async () => {
    const res1 = await getSendWorkUser(searchParams.get('workorderid'), searchParams.get('baseid'))
    const res2 = await getSendWorkUserAssign(searchParams.get('workorderid'))
    setUsers(res1)
    setAssignUsers(res2)
  }

  useEffect(() => {
    getUsers()
  }, [])

  const submitAssign = async () => {
    if (selectUsers.length === 0) {
      Toast.info('没有选择工人！')
      return
    }
    const res = await submitSendWork({
      id: searchParams.get('workorderid'),
      assign: selectUsers
    })
    if (res) {
      Toast.success('操作成功！')
    }
    await getUsers()
  }

  return <>
    <Tabs align='start' sticky>
      <Tabs.TabPane title='派工'>
        <Checkbox.Group onChange={setSelectUsers}>
          {
            users?.map(e => (
              <SendWorkItem key={e.id} isSel={true} data={e} />
            ))
          }
        </Checkbox.Group>

        <div style={{ margin: 12 }}>
          <div className='btn_sub_bottom'>
            <Button round type="primary" block onClick={submitAssign}>指派</Button>
          </div>
        </div >
      </Tabs.TabPane>
      <Tabs.TabPane title='已派记录'>
        {
          assignUsers?.map(e => (
            <SendWorkItem key={e.id} data={e} />
          ))
        }
      </Tabs.TabPane>
    </Tabs >
  </>
}
export default PlantWorkOrderSendWork