import request from "@/utils/request"

// 获取种植任务
export const getPalntTask = async (id) => {
  let res = await request.get(`/planttask/${id}`)
  return res
}

// 种植任务完成
export const donePalntTask = async (id) => {
  let res = await request.put(`/planttask/${id}/submit`)
  return res
}

