import { useSearchParams } from 'react-router-dom'
import PlotListItem from '../components/PlotListItem'
import { useEffect } from 'react'
import { getPlotByIds } from '../service'
import { useState } from 'react'

const PlantPlot = () => {
  const [plotlandList, setPlotlandList] = useState([])
  const [searchParams] = useSearchParams()


  document.title = '地块列表'

  useEffect(() => {
    const getData = async () => {
      let res = await getPlotByIds(searchParams.get('ids'))
      setPlotlandList(res)
    }

    getData()
  }, [searchParams])

  return <>
    {
      plotlandList.map(i => (
        <PlotListItem key={i.id} data={i} />
      ))
    }
  </>
}
export default PlantPlot