import { Empty, List } from 'react-vant'
import { useEffect, useState } from 'react'
import { getPlantingPlans } from '../../service'
import PlantingPlanListItem from '../PlantingPlanListItem'

// props:{userid,baseid,status,stageTypes}
const PlantingPlanList = (props) => {
  const [list, setList] = useState([])
  const [finished, setFinished] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (props.baseid) {
      onRefresh()
    }
  }, [props])


  const onload = async () => {
    let res = await getPlantingPlans(props.userid, props.baseid, props.status, currentPage)
    let listn = list.concat(res.list)
    if (listn.length >= res.total) {
      setFinished(true)
    } else {
      setCurrentPage(currentPage + 1)
    }
    setList(listn)
  }

  const onRefresh = () => {
    setList([])
    setCurrentPage(1)
    setFinished(false)
  }

  return <List finished={finished} onLoad={onload}>
    {
      list.length === 0
        ? <Empty description="暂无数据" />
        : list.map(i => (
          <PlantingPlanListItem key={i.id} data={i} stageTypes={props.stageTypes} onRefresh={onRefresh} />
        ))
    }

  </List>
}

export default PlantingPlanList