import { Button, Card, DatetimePicker, Form, Input, Picker, Toast } from "react-vant"

import style from './index.module.scss'
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { createPlantOrder, getDynamicForm } from "../service"
import { dateFormat } from "@/utils/timeHelper"
import { useStore } from "@/store/useStore"
import { getPalntTask } from "@/pages/PlantTask/service"

const PlantWorkOrderCreate = () => {
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const [dynamicForms, setDynamicForms] = useState()
  const [number, setNumber] = useState(0)
  const [taskInfo, setTaskInfo] = useState()
  const navigate = useNavigate()
  const store = useStore()
  document.title = '创建工单'

  useEffect(() => {
    const getData = async () => {
      // 获取序号
      let resTask = await getPalntTask(searchParams.get('taskid'))
      setTaskInfo(resTask)
      if (resTask.orders.length === 0) {
        setNumber(1)
        form.setFieldValue('工单名称', `${resTask.name}-1`)
      } else {
        const getMaxNumber = resTask.orders.reduce((max, obj) => {
          return Math.max(max, obj.number)
        }, -Infinity)
        setNumber(getMaxNumber + 1)
        form.setFieldValue('工单名称', `${resTask.name}-${getMaxNumber + 1}`)
      }


      //获取动属性
      let res = await getDynamicForm(searchParams.get('taskid'))
      setDynamicForms(res.filter(d => d.name !== '计划开始时间' && d.name !== '计划结束时间'))
    }
    if (searchParams.get('taskid')) {
      getData()
    }

  }, [searchParams])

  const showDynamic = () => {
    const items = []
    dynamicForms?.forEach((d, i) => {
      switch (d.type) {
        case 'datetime':  // 时间
          items.push(
            <Form.Item key={`dynamic_${i}`} isLink name={d.name} label={d.name} trigger='onConfirm' controlAlign='right'
              onClick={(_, action) => { action.current?.open() }}>
              <DatetimePicker popup type='date' minDate={new Date(2010, 0, 1)} >
                {(val) => (<Input placeholder={`请选择${d.name}`} readOnly value={val ? dateFormat(val) : ''} />)}
              </DatetimePicker>
            </Form.Item>
          )
          break
        case 'dropdown':  // 下拉选择
          items.push(
            <Form.Item key={`dynamic_${i}`} isLink name={d.name} label={d.name} controlAlign='right'
              trigger='onConfirm' onClick={(_, action) => { action.current?.open() }}>
              <Picker popup columns={d.data}>
                {(val) => (<Input placeholder={`请选择${d.name}`} readOnly value={val ? val : ''} />)}
              </Picker>
            </Form.Item>
          )
          break
        default:  // 默认字符串
          items.push(
            <Form.Item key={`dynamic_${i}`} name={d.name} label={d.name} controlAlign='right'>
              <Input placeholder={`请输入${d.name}`} />
            </Form.Item>
          )
          break
      }
    })
    return items
  }

  const submit = async () => {
    try {
      await form.validateFields()
      const info = {
        taskid: searchParams.get('taskid'),
        name: form.getFieldValue('工单名称'),
        planstartTime: dateFormat(form.getFieldValue('开始时间')),
        planendTime: dateFormat(form.getFieldValue('结束时间')),
        status: 1,
        operator: store.userStore.userInfo.name,
        operatorId: store.userStore.userInfo.id,
        number: number,
        dynamic: []
      }
      dynamicForms.forEach(e => {
        let val = form.getFieldValue(e.name)
        if (typeof val === 'undefined') {
          val = ''
        } else if (e.type === 'datetime') {
          val = dateFormat(val)
        }
        info.dynamic.push({ key: e.name, value: val })
      })
      let res = await createPlantOrder(info)
      if (res) {
        Toast.success({ message: '操作成功', onClose: () => { navigate(-1) } })
      }
    } catch (errorList) {
    }
  }

  return <>
    <Form form={form} showValidateMessage={false} >
      <Card round className={style.card}>
        <Card.Body style={{ padding: 0 }}>
          <Form.Item name='工单名称' label='工单名称' rules={[{ required: true }]} controlAlign='right'
            initialValue={taskInfo?.name}>
            <Input placeholder='请输入工单名称' />
          </Form.Item>

          <Form.Item isLink name='开始时间' label='开始时间' rules={[{ required: true }]} controlAlign='right'
            trigger='onConfirm' onClick={(_, action) => { action.current?.open() }}>
            <DatetimePicker popup type='date' minDate={new Date(2010, 0, 1)} >
              {(val) => (<Input placeholder='请选择开始时间' readOnly value={val ? dateFormat(val) : ''} />)}
            </DatetimePicker>
          </Form.Item>

          <Form.Item isLink name='结束时间' label='结束时间' rules={[{ required: true }]} controlAlign='right'
            trigger='onConfirm' onClick={(_, action) => { action.current?.open() }}>
            <DatetimePicker popup type='date' minDate={new Date(2010, 0, 1)}>
              {(val) => (<Input placeholder='请选择结束时间' readOnly value={val ? dateFormat(val) : ''} />)}
            </DatetimePicker>
          </Form.Item>
        </Card.Body>
      </Card>
      <Card round className={style.card}>
        <Card.Body style={{ padding: 0 }}>
          {showDynamic()}
        </Card.Body>
      </Card>
    </Form>
    <div style={{ margin: 12 }}>
      <div className='btn_sub_bottom'>
        <Button round type="primary" block onClick={submit}>提交</Button>
      </div>
    </div >
  </>
}
export default PlantWorkOrderCreate