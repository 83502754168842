import { Tabs } from 'react-vant'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from '@/store/useStore'
import PlantingPlanList from './components/PlantingPlanList'

const PlantingPlan = () => {
  const params = useParams() // userid,baseid,stageTypes
  document.title = '种植计划'
  const store = useStore()

  useEffect(() => {
    store.userStore.getUserInfo(params.userid)
  }, [params.userid, store.userStore])

  return <>
    <Tabs align='start' sticky>
      <Tabs.TabPane title='未完成'>
        <PlantingPlanList userid={params.userid} baseid={params.baseid} status={0} stageTypes={params.stageTypes} />
      </Tabs.TabPane>
      <Tabs.TabPane title='已完成'>
        <PlantingPlanList userid={params.userid} baseid={params.baseid} status={2} stageTypes={params.stageTypes} />
      </Tabs.TabPane>
    </Tabs >
  </>
}
export default PlantingPlan