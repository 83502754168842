
import { Cell, FloatingBall, Image, Tabs, Typography } from 'react-vant'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getWorkOrderDetail } from '../service'
import { decode } from 'js-base64'

import style from './index.module.scss'
import WorkOrderLogItem from '../components/WorkOrderLogItem'

const PlantWorkOrderDetail = () => {
  const [model, setModel] = useState(null)
  document.title = '工单详细'
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    getDetail(params.id)
  }, [params])

  const getDetail = async (id) => {
    let res = await getWorkOrderDetail(id)
    setModel(res)
  }

  const getLogOperators = (logs) => {
    return [...new Set(logs?.map(o => { return o.operator }))].map(o => (`${o}  `))
  }

  const toWorkOrderReport = () => {
    navigate('/WorkOrder/Report?orderid=' + params.id)
  }

  const deleteLog = (e) => {
    model.log = model.log.filter(l => l.id !== e)
    setModel({ ...model })
  }

  return <>
    <Tabs sticky>
      <Tabs.TabPane title='工单详情'>
        <Cell.Group card={true} style={{ marginTop: 12 }}>
          <Cell title='工单名称' value={model?.name} />
          <Cell title='开始时间' value={model?.planstartTime} />
          <Cell title='结束时间' value={model?.planendTime} />
        </Cell.Group>
        <Cell.Group card={true} style={{ marginTop: 12 }}>
          {
            model?.dynamic.map(row => (
              <Cell key={row.key} title={row.key} value={row.value} />
            ))
          }
        </Cell.Group>
      </Tabs.TabPane>
      <Tabs.TabPane title='作业指导'>
        <div className={style.direction}>
          <div dangerouslySetInnerHTML={{ __html: model ? decode(model.instruction) : '' }}></div>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane title='常见问题'>
        <div className={style.direction}>
          <div dangerouslySetInnerHTML={{ __html: model ? decode(model.faq) : '' }}></div>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane title='日志记录'>
        {
          model?.log.length > 0 && <div className={style.collaboration}>
            <Typography.Text ellipsis size='lg'>
              <Typography.Text size='lg' type='primary'>协作人：</Typography.Text>
              {
                getLogOperators(model?.log)
              }
            </Typography.Text>
          </div>
        }

        {
          model?.log.map(i => (
            <WorkOrderLogItem key={i.id} data={i} woperatorId={model.operatorId} onRefresh={deleteLog} />
          ))
        }
      </Tabs.TabPane>
    </Tabs >

    <FloatingBall offset={{ right: 0, bottom: '15vh' }}>
      <Image src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/plusworkorder%402x.png' onClick={toWorkOrderReport}></Image>
    </FloatingBall>
  </>
}
export default PlantWorkOrderDetail