
import { Dialog, Flex, Image, ImagePreview, Typography } from 'react-vant'
import style from './index.module.scss'
import { useStore } from '@/store/useStore'
import { deleteWorkOrder } from '../../service'
import { cosDelete } from '@/utils/cosHelper'

const WorkOrderLogItem = (props) => {
  const images = props.data?.photos ?? []
  const store = useStore()
  const deleteLog = () => {

    Dialog.confirm({
      message: '确认删除吗？'
    }).then(async () => {
      let res = await deleteWorkOrder(props.data?.id)
      if (res) {
        props.onRefresh?.(props.data?.id)
        props.data?.photos.forEach((img) => {
          cosDelete(img.substr(img.indexOf('files')))
        })
      }
    }).catch(() => {
    })

  }

  return <div className={style.logItem}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <span className={style.logItem_name}>{props.data?.operator}</span>
      {/* <span className={style.logItem_subDate}>{timeFormat(props.data?.submitTime, 'yyyy-mm-dd')}</span> */}
    </div>
    {
      props.data?.description && <Typography.Text ellipsis={{
        rows: 2,
        collapseText: '收起',
        expandText: '展开',
      }} className={style.logItem_text}>{props.data?.description}</Typography.Text>
    }
    {
      !props.data?.description && <div style={{ height: 5 }} />
    }
    {
      props.data?.photos && <Flex wrap='wrap' gutter={[10, 10]}>
        {props.data?.photos.map((i, index) => (
          <Flex.Item key={index} span={8} >
            <Image fit='cover' height='100' src={`${i}?imageMogr2/thumbnail/256x`} onClick={() => {
              ImagePreview.open({
                closeable: true,
                showIndicators: true,
                images,
                startPosition: index
              })
            }} />
          </Flex.Item>
        ))}
      </Flex>
    }
    {
      props.woperatorId === store.userStore.userInfo?.id && <div style={{ textAlign: 'right' }}>
        <Typography.Text type="danger" onClick={deleteLog} style={{ cursor: 'pointer' }}>删除</Typography.Text>
      </div>
    }
  </div>
}
export default WorkOrderLogItem