import { useSearchParams } from 'react-router-dom'
import PlotListItem from '../components/PlotListItem'
import { useEffect } from 'react'
import { useState } from 'react'
import { List, hooks } from 'react-vant'
import { getPlot } from '../service'
import SelectBaseByUser from '@/pages/Base/components/SelectBaseByUser'

const PlotManage = () => {
  const [page, setPage] = useState(1)
  const [finished, setFinished] = useState(true)
  const [plotlandList, setPlotlandList] = useState([])
  const [baseId, setBaseId] = useState()
  const [searchParams] = useSearchParams()
  document.title = '地块列表'

  useEffect(() => {
    setBaseId(searchParams.get('baseid'))
  }, [searchParams])

  hooks.useUpdateEffect(() => {
    if (baseId) {
      setPlotlandList([])
      setPage(1)
      setFinished(false)
    }
  }, [baseId])

  const getPlotList = async () => {
    let res = await getPlot(baseId, page)
    let list = plotlandList.concat(res.list)
    if (list.length >= res.total) {
      setFinished(true)
    } else {
      setPage(page + 1)
    }
    setPlotlandList(list)
  }

  return <>
    <List finished={finished} onLoad={getPlotList}>
      {
        plotlandList.map(i => (
          <PlotListItem key={i.id} data={i} />
        ))
      }
    </List>
    <SelectBaseByUser userid={searchParams.get('userid')} baseid={searchParams.get('baseid')} onBaseChange={setBaseId} />
  </>
}
export default PlotManage