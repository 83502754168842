import React, { Component } from 'react'
import { Image } from 'react-vant'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, errorInfo) {
    this.setState({ hasError: true })
    // 在这里可以记录错误信息到日志
    console.error(error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      return <div>
        <Image src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/error.png' />
      </div>
    }
    return this.props.children
  }
}

export default ErrorBoundary