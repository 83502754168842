import { Image, Tag } from 'react-vant'
import style from './index.module.scss'
import { useNavigate } from 'react-router-dom'


const TaskItem = (props) => {
  const navigate = useNavigate()

  const toPlantTaskManage = (id) => {
    navigate(`/Task/Manage/${id}`)
  }

  return <div className={style.taskItem} onClick={() => { toPlantTaskManage(props.data?.id) }}>
    <Image className={style.taskItem_divider} width={2} height={20} src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/xuxian2.png' />
    <div className={style.taskItem_content}>
      <Image width={32} height={32} src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/tasktype/0.png' />
      <div className={style.taskItem_cell}>
        <span className={style.taskItem_name}>{props.data?.name}</span>
        <span className={style.taskItem_intro}>{props.data?.timepoint}</span>
      </div>
      <Tag color='#E6FAF7' textColor='#00CBB3' style={{ height: 20, marginTop: 6, display: props.data?.status === '1' ? '' : 'none' }}>进行中</Tag>
      <Tag color='#FFf2e6' textColor='#FF7F00' style={{ height: 20, marginTop: 6, display: props.data?.status === '2' ? '' : 'none' }}>已完成</Tag>
    </div>
  </div>

}
export default TaskItem