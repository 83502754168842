import COS from 'cos-js-sdk-v5'
import { v4 as uuidv4 } from 'uuid'

var cos = new COS({
  getAuthorization: function (options, callback) {
    var url = process.env.REACT_APP_PUBLIC_URL + '/CosAccess' //'https://api.longyaoyun.cn/wx/lyypt/CosAccess'
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.onload = function (e) {
      try {
        var data = JSON.parse(e.target.responseText)
        var credentials = data.data
      } catch (e) {
      }
      if (!data || !credentials) {
        return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
      };
      callback({
        TmpSecretId: credentials.TmpSecretId,
        TmpSecretKey: credentials.TmpSecretKey,
        SecurityToken: credentials.SecurityToken,
        //StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: credentials.ExpiredTime, // 时间戳，单位秒，如：1580000000
      })
    }
    xhr.send()
  }
})




// 实际业务中需要自己实现对应上传逻辑
export const cosUpload = async (file) => {
  try {
    let data = await cos.uploadFile({
      Bucket: 'pms-1257839808', /* 填入您自己的存储桶，必须字段 */
      Region: 'ap-beijing',  /* 存储桶所在地域，例如ap-beijing，必须字段 */
      Key: `files/种植计划/任务/${uuidv4()}${file.name.substr(file.name.lastIndexOf('.'))}`,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
      Body: file, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
    })

    return { url: `https://${data.Location}`, thumbnail: `https://${data.Location}?imageMogr2/thumbnail/200x` }
  } catch (err) {
    console.log(err)
  }
}

export const cosDelete = async (key) => {
  try {
    await cos.deleteObject({
      Bucket: 'pms-1257839808',
      Region: 'ap-beijing',
      Key: decodeURIComponent(key)
    })
  } catch (err) {
    console.log(err)
  }
}

export const cosGetList = async (path) => {
  try {
    const res = await cos.getBucket({
      Bucket: 'pms-1257839808',
      Region: 'ap-beijing',
      Prefix: path,
      Marker: path
    })
    return res.Contents
  } catch (err) {
    console.log(err)
  }
}