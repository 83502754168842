import { makeAutoObservable } from 'mobx'
import request from "@/utils/request"

class UserStore {
  userInfo = null // 定义数据
  constructor() {
    makeAutoObservable(this)  // 响应式处理
  }

  // 获取关联账号信息
  getUserInfo = async (id) => {
    const res = await request.get(`/users/${id}/pms`)
    this.userInfo = res
  }
}

const user = new UserStore()
export default user