import request from "@/utils/request"


export const getWorkOrderDetail = async (id) => {
  let res = await request.get(`/plantorder/${id}`)
  return res
}

// 获取工单-信息
export const getPalntOrderMsg = async (id) => {
  let res = await request.get(`/plantorder/${id}/msg`)
  return res
}


// 获取工单-作业指导
export const getPalntOrderGuide = async (id) => {
  let res = await request.get(`/plantorder/${id}/guide`)
  return res
}


// 获取工单-常见问题
export const getPalntOrderFaq = async (id) => {
  let res = await request.get(`/plantorder/${id}/faq`)
  return res
}


// 获取工单-日志记录
export const getPalntOrderLog = async (id) => {
  let res = await request.get(`/plantorder/${id}/log`)
  return res
}

// 创建工单-获取任务动态表单
export const getDynamicForm = async (taskId) => {
  let res = await request.get(`/planttask/${taskId}/dynamic`)
  return res
}

// 创建工单
export const createPlantOrder = async (info) => {
  let res = await request.post('/plantorder', info)
  return res
}

// 删除工单
export const delPlantOrder = async (id) => {
  let res = await request.delete(`/plantorder/${id}`)
  return res
}

// 编辑工单
export const savePlantOrder = async (id, info) => {
  let res = await request.put(`/plantorder/${id}`, info)
  return res
}

// 完成工单
export const donePlantOrder = async (id) => {
  let res = await request.put(`/plantorder/${id}/submit`)
  return res
}

// 派工-获取未指派人员
export const getSendWorkUser = async (workorderid, baseid) => {
  let res = await request.get(`/plantorder/${workorderid}/users`, { params: { baseid: baseid } })
  return res
}

// 派工-获取已指派人员
export const getSendWorkUserAssign = async (id) => {
  let res = await request.get(`/plantorder/${id}/assignrecord`)
  return res
}

// 指派-提交
export const submitSendWork = async (info) => {
  let res = await request.post('/plantorder/assign', info)
  return res
}

// 任务上报
export const submitReport = async (info) => {
  let res = await request.post('/plantorderlog', info)
  return res
}

// 获取关联账号信息
export const getUserInfo = async (id) => {
  let res = await request.get(`users/${id}/pms`)
  return res
}

// 获取工单状态
export const getWorkOrderStatus = async (id) => {
  let res = await request.get(`plantorder/${id}/operator`)
  return res
}

// 删除任务上报记录
export const deleteWorkOrder = async (id) => {
  let res = await request.delete(`plantorderlog/${id}`)
  return res
}