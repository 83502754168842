import { Button, Card, DatetimePicker, Form, Input, Picker, Toast } from "react-vant"

import style from './index.module.scss'
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { getPalntOrderMsg, savePlantOrder } from "../service"
import { dateFormat } from "@/utils/timeHelper"

const PlantWorkOrderEdit = () => {
  const [workOrderInfo, setWorkOrderInfo] = useState()
  const [form] = Form.useForm()
  const params = useParams()
  const navigate = useNavigate()
  document.title = '编辑工单'

  useEffect(() => {
    const getData = async () => {
      let res = await getPalntOrderMsg(params.id)
      res.dynamic = res.dynamic.filter(d => d.key !== '计划开始时间' && d.key !== '计划结束时间')
      res.planstartTime = new Date(res.planstartTime)
      res.planendTime = new Date(res.planendTime)
      setWorkOrderInfo(res)
    }
    getData()
  }, [params])


  const showBase = () => {
    return [
      <Form.Item key={`base_${Math.random()}`} name='工单名称' label='工单名称' rules={[{ required: true }]} controlAlign='right'
        initialValue={workOrderInfo?.name}>
        <Input placeholder='请输入工单名称' />
      </Form.Item>,
      <Form.Item key={`base_${Math.random()}`} isLink name='开始时间' label='开始时间' rules={[{ required: true }]} controlAlign='right'
        trigger='onConfirm' onClick={(_, action) => { action.current?.open() }}
        initialValue={workOrderInfo?.planstartTime}>
        <DatetimePicker popup type='date' minDate={new Date(2010, 0, 1)}>
          {(val) => (<Input placeholder='请选择开始时间' readOnly value={val ? dateFormat(val) : ''} />)}
        </DatetimePicker>
      </Form.Item>,
      <Form.Item key={`base_${Math.random()}`} isLink name='结束时间' label='结束时间' rules={[{ required: true }]} controlAlign='right'
        trigger='onConfirm' onClick={(_, action) => { action.current?.open() }}
        initialValue={workOrderInfo?.planendTime}>
        <DatetimePicker popup type='date' minDate={new Date(2010, 0, 1)}>
          {(val) => (<Input placeholder='请选择结束时间' readOnly value={val ? dateFormat(val) : ''} />)}
        </DatetimePicker>
      </Form.Item>,

    ]
  }

  const showDynamic = () => {
    const items = []
    workOrderInfo?.dynamic.forEach((d, i) => {
      switch (d.type) {
        case 'datetime':  // 时间
          items.push(
            <Form.Item key={`dynamic_${i}`} isLink name={d.key} label={d.key} trigger='onConfirm' controlAlign='right'
              onClick={(_, action) => { action.current?.open() }} initialValue={new Date(d.value)}>
              <DatetimePicker popup type='date' minDate={new Date(2010, 0, 1)} >
                {(val) => (<Input placeholder={`请选择${d.key}`} readOnly value={val ? dateFormat(val) : ''} />)}
              </DatetimePicker>
            </Form.Item>
          )
          break
        case 'dropdown':  // 下拉选择
          items.push(
            <Form.Item key={`dynamic_${i}`} isLink name={d.key} label={d.key} controlAlign='right'
              trigger='onConfirm' onClick={(_, action) => { action.current?.open() }}
              initialValue={d.value}>
              <Picker popup columns={d.data}>
                {(val) => (<Input placeholder={`请选择${d.key}`} readOnly value={val ? val : ''} />)}
              </Picker>
            </Form.Item>
          )
          break

        default:  // 默认字符串
          console.log(d)
          items.push(
            <Form.Item key={`dynamic_${i}`} name={d.key} label={d.key} controlAlign='right'
              initialValue={d.value}>
              <Input placeholder={`请输入${d.key}`} />
            </Form.Item>
          )
          break
      }
    })
    return items
  }

  const submit = async () => {
    try {
      await form.validateFields()
      const info = {
        name: form.getFieldValue('工单名称'),
        planstartTime: dateFormat(form.getFieldValue('开始时间')),
        planendTime: dateFormat(form.getFieldValue('结束时间')),
        dynamic: []
      }
      workOrderInfo?.dynamic.forEach(e => {
        let val = form.getFieldValue(e.key)
        if (typeof val === 'undefined') {
          val = ''
        } else if (e.type === 'datetime') {
          val = dateFormat(val)
        }
        info.dynamic.push({ key: e.key, value: val })
      })
      let res = await savePlantOrder(params.id, info)
      if (res) {
        Toast.success({ message: '操作成功', onClose: () => { navigate(-1) } })
      }
    } catch (errorList) {
    }
  }

  return <>
    <Form form={form} showValidateMessage={false}>
      <Card round className={style.card}>
        <Card.Body style={{ padding: 0 }}>
          {showBase()}
        </Card.Body>
      </Card>
      <Card round className={style.card}>
        <Card.Body style={{ padding: 0 }}>
          {showDynamic()}
        </Card.Body>
      </Card>
    </Form>
    <div style={{ margin: 12 }}>
      <div className='btn_sub_bottom'>
        <Button round type="primary" block onClick={submit}>提交</Button>
      </div>
    </div >
  </>
}
export default PlantWorkOrderEdit