import SelectBaseByUser from "@/pages/Base/components/SelectBaseByUser"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Search, Tabs } from "react-vant"
import PersonList from "../components/PersonList"

const PersonManage = () => {
  const [searchKey, setSearchKey] = useState('')
  const [searchParams] = useSearchParams()
  document.title = '人员管理'

  const search = () => {
    console.log(searchKey)
  }

  return <>
    <Search value={searchKey} placeholder="搜索成员姓名" onChange={setSearchKey} onSearch={search} />
    <Tabs >
      <Tabs.TabPane title='基地人员'>
        <PersonList employeeType={0} />
      </Tabs.TabPane>
      <Tabs.TabPane title='基地工人'>
        <PersonList employeeType={1} />
      </Tabs.TabPane>
      <Tabs.TabPane title='签约农户'>
        <PersonList employeeType={2} />
      </Tabs.TabPane>
    </Tabs >
    <SelectBaseByUser userid={searchParams.get('userid')} baseid={searchParams.get('baseid')} onBaseChange={(e) => console.log(e)} />
  </>
}
export default PersonManage