import { useState } from "react"
import { List } from "react-vant"
import { getBasePerson } from "../../service"

const PersonList = (props) => {
  const [page, setPage] = useState(1)
  const [finished, setFinished] = useState(true)
  const [dataList, setDataList] = useState([])

  const getDataList = async () => {
    let res = await getBasePerson(props.baseId, props.employeeType, props.searchKey, page)
    let list = dataList.concat(res.list)
    if (list.length >= res.total) {
      setFinished(true)
    } else {
      setPage(page + 1)
    }
    setDataList(list)
  }



  return <List finished={finished} onLoad={getDataList}>
    <div>123</div>
  </List>
}
export default PersonList