
const Home = () => {
  return <>
    <div style={{ display: 'flex', flexDirection: 'column' }}>

      <div>REACT_APP_BASE_URL={process.env.REACT_APP_BASE_URL}</div>
      <div>REACT_APP_PUBLIC_URL={process.env.REACT_APP_PUBLIC_URL}</div>

    </div>
  </>
}
export default Home