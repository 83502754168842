import { Image, Tabs } from 'react-vant'
import style from './index.module.scss'
import TraceItemPanes from '../components/TraceItemPanes'
import { useEffect, useState } from 'react'
import { getTraceDetailed } from '../service'
import JiDiPanes from '../components/JiDiPanes'
import { useParams } from 'react-router-dom'
import ErrorBoundary from '@/components/ErrorBoundary'

const TraceabilityDetailed = () => {
  const [traceInfo, setTraceInfo] = useState({ baseinfo: null, harvestProcess: null, inspection: null, planting: null, release: null, storage: null, transport: null })
  const params = useParams()

  useEffect(() => {
    const getData = async () => {
      let res = await getTraceDetailed(params.code)
      setTraceInfo(res)
    }
    getData()
  }, [params])

  const tabsTabPanes = [
    {
      title: <div className={style.tabPaneTitle}>
        <Image width='35' height='35' src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/zs-info.png' />
        <span>基地信息</span>
      </div>,
      content: <JiDiPanes data={traceInfo?.baseinfo} number={traceInfo?.number} />
    },
    {
      title: <div className={style.tabPaneTitle}>
        <Image width='35' height='35' src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/zs-zz.png' />
        <span>种植环节</span>
      </div>,
      content: <TraceItemPanes data={traceInfo?.planting} />
    },
    {
      title: <div className={style.tabPaneTitle}>
        <Image width='35' height='35' src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/zs-cs.png' />
        <span>采收加工</span>
      </div>,
      content: <TraceItemPanes data={traceInfo?.harvestProcess} />
    },
    {
      title: <div className={style.tabPaneTitle}>
        <Image width='35' height='35' src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/zs-cc.png' />
        <span>仓储</span>
      </div>,
      content: <TraceItemPanes data={traceInfo?.storage} />
    },
    {
      title: <div className={style.tabPaneTitle}>
        <Image width='35' height='35' src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/zs-zj.png' />
        <span>质检</span>
      </div>,
      content: <TraceItemPanes data={traceInfo?.inspection} />
    },
    {
      title: <div className={style.tabPaneTitle}>
        <Image width='35' height='35' src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/zs-fx.png' />
        <span>放行</span>
      </div>,
      content: <TraceItemPanes data={traceInfo?.release} />
    },
    {
      title: <div className={style.tabPaneTitle}>
        <Image width='35' height='35' src='https://pms-1257839808.cos.ap-beijing.myqcloud.com/weapp/images/zs-ys.png' />
        <span>运输</span>
      </div>,
      content: <TraceItemPanes data={traceInfo?.transport} />
    }
  ]

  return <>
    <Tabs sticky>
      {tabsTabPanes.map((item, index) => (
        <Tabs.TabPane key={index} title={item.title}>
          <ErrorBoundary>
            {item.content}
          </ErrorBoundary>
        </Tabs.TabPane>
      ))}
    </Tabs>
  </>
}
export default TraceabilityDetailed